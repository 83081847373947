import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  class: "header_wrapper"
};
const _hoisted_2 = {
  class: "header_left"
};
const _hoisted_3 = {
  class: "header_right"
};
const _hoisted_4 = {
  key: 0,
  class: "iconfont icon-quxiaoquanping"
};
const _hoisted_5 = {
  key: 1,
  class: "iconfont icon-quanping"
};
const _hoisted_6 = {
  class: "user"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_avatar = _resolveComponent("el-avatar");
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item");
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");
  const _component_el_dropdown = _resolveComponent("el-dropdown");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [!$setup.isOpen ? (_openBlock(), _createElementBlock("span", {
    key: 0,
    class: "iconfont icon-yihangyige",
    onClick: _cache[0] || (_cache[0] = (...args) => $setup.open && $setup.open(...args))
  })) : (_openBlock(), _createElementBlock("span", {
    key: 1,
    class: "iconfont icon-yihangyige1",
    onClick: _cache[1] || (_cache[1] = (...args) => $setup.close && $setup.close(...args))
  }))]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", {
    class: "fullScreen_icon",
    onClick: _cache[2] || (_cache[2] = (...args) => $setup.toggleFullScreen && $setup.toggleFullScreen(...args))
  }, [$setup.isFullscreen ? (_openBlock(), _createElementBlock("span", _hoisted_4)) : (_openBlock(), _createElementBlock("span", _hoisted_5))]), _createVNode(_component_el_dropdown, {
    trigger: "click"
  }, {
    dropdown: _withCtx(() => [_createVNode(_component_el_dropdown_menu, null, {
      default: _withCtx(() => [_createVNode(_component_el_dropdown_item, {
        onClick: $setup.goSet
      }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode("用户设置")])),
        _: 1
      }, 8, ["onClick"]), _createVNode(_component_el_dropdown_item, {
        onClick: $setup.logout
      }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode("退出登录")])),
        _: 1
      }, 8, ["onClick"])]),
      _: 1
    })]),
    default: _withCtx(() => [_createElementVNode("div", _hoisted_6, [_createVNode(_component_el_avatar, {
      size: 35,
      src: $setup.userInfo.avatar ? $setup.requestImage + $setup.userInfo.avatar : $setup.avatar
    }, null, 8, ["src"]), _createElementVNode("span", null, _toDisplayString($setup.userInfo.username), 1)])]),
    _: 1
  })])]);
}